require('jquery');
require('popper.js');
require('bootstrap');
require("slick-carousel/slick/slick.min.js");

require('./../scss/app.scss');

window.noZensmooth = true;

require('./common/script.js');
require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/shops.js');
import LiveEvent from "../../components/live/live";
import zenscroll from "zenscroll";

/**
 * Created by MooWPro on 13/11/2017.
 */
var placemarks = [];
var geocollection = {};

ymaps.ready(
    function init() {
        var myMap = new ymaps.Map('map', {
            center: [55.752411, 37.609407],
            zoom: 10,
            controls: ['fullscreenControl','zoomControl']
        }, {
            searchControlProvider: 'yandex#search'
        });

        myMap.behaviors.disable('scrollZoom');

        createMarkByCityId($('.shops-city-select').val());

        $('.shops-city-select').change(function(){
            var city_id = $(this).val();
            var slug = $(this).children('option[value='+city_id+']').data('slug');
            window.location.href='/store/'+slug;
        });

        function createMarkByCityId(city_id){
            placemarks = [];
            for (var i = 0, l = groups.length; i < l; i++) {
                if(groups[i].id == city_id) {
                    createGroup(groups[i]);
                }
            }
        }

        function createGroup (group) {
            geocollection = new ymaps.GeoObjectCollection(null, { preset: 'islands#blueIcon' });
            myMap.geoObjects.add(geocollection);
            for (var j = 0, m = group.items.length; j < m; j++) {
                var placemark = new ymaps.Placemark(group.items[j].center, { balloonContent: group.items[j].name });
                geocollection.add(placemark);
                placemarks.push({id: group.items[j].id, mark: placemark});
            }
        }

        if(groups[0].items.length <= 1){
            myMap.setCenter([groups[0].items[0].center[0], groups[0].items[0].center[1]],10);
            console.log(groups[0].items[0].center[0]);
        }else{
            myMap.setBounds(myMap.geoObjects.getBounds());
        }
    }
);

new LiveEvent('click', 'a.balloon-link', function shopScrollBalloon(e) {
    e.preventDefault();
    var shop_id = $(this).data('shop-id');
    for (var i = 0, l = placemarks.length; i < l; i++) {
        if(placemarks[i].id == shop_id) {
            placemarks[i].mark.balloon.open();
        }
    }

    let target = null;
    target = document.querySelector('#map');
    let offset = this.dataset.offset || 0;
    if (target) {
        let viewportOffset = target.getBoundingClientRect();
        let top = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
        zenscroll.toY(top + viewportOffset.top + offset, 500);
    }

    return false;
});
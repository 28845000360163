$(document).ready(function(){
    
    $(document).on("click", ".burger", function () {
       $(".burger").toggleClass("close");
       $(".header-top__nav").toggleClass("show");
    });     
    
    $('.slider-big').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots:false,
      fade: true,
      asNavFor: '.slider-small',
      infinite:true,
      initialSlide: 0,
      responsive: [
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               dots: false,
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               dots: true,
               infinite:true,
            }
         }
      ]

   });

   $('.slider-small').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.slider-big',
      dots: false,
      infinite:true,
      initialSlide: 0,
      centerMode:false,
      focusOnSelect: true,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               arrows: true,
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               arrows: true,
            }
         },
         {
            breakpoint: 480,
            settings: {
               arrows: false
            }
         }
      ]
   });

   $('.slider').on('init', function(event, slick, direction){
      if( slick.slideCount < 3) {
         slick.unslick()
      }
    });

    $('.slider').slick({
       slidesToShow: 3,
       slidesToScroll: 3,
       arrows: true,
       dots: false,
       infinite:true,
       responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
             breakpoint: 480,
             settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
               dots: true
             }
           }
       ]
    });


    
    $('.tabs-triggers__item').click(function(e) { 
      e.preventDefault();
            
      $('.tabs-triggers__item').removeClass('tabs-triggers__item--active');
      $('.tabs-content__item').removeClass('tabs-content__item--active');

      $(this).addClass('tabs-triggers__item--active');
      $($(this).attr('href')).addClass('tabs-content__item--active');
   });

   $('.tabs-triggers__item:first').click(); 
});    